<template>
  <NuxtLinkLocale :class="['social-icon', rootClasses]" :to="currentVariant.link" target="_blank" @click="handleClick">
    <component :is="currentVariant.icon" :key="currentVariant.icon" class="social-icon__image" />
    <p class="social-icon__count">{{ countFormatted }}</p>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import { HeaderEvents } from '~/repository/amplitude/events/header';

import {
  ESocialIconVariant,
  type TSocialIconVariantsData,
  type ISocialIconProps,
  type ISocialIconVariantData,
} from './SocialIcon.types';
import { EHeaderSocialLinkClickedPlaces } from '~/repository/amplitude/types/header.types';

const props = defineProps<ISocialIconProps>();
const { variant, count } = toRefs(props);

const variants: TSocialIconVariantsData = {
  [ESocialIconVariant.VK]: {
    icon: 'LazySvgoFooterVk',
    link: useLinks().VK,
  },
  [ESocialIconVariant.TELEGRAM]: {
    icon: 'LazySvgoFooterTelegram',
    link: useLinks().TELEGRAM,
  },
  [ESocialIconVariant.INSTAGRAM]: {
    icon: 'LazySvgoFooterInstagram',
    link: useLinks().INSTAGRAM,
  },
};

const rootClasses = computed(() => ({
  [`social-icon--${variant.value}`]: true,
}));
const currentVariant = computed<ISocialIconVariantData>(() => variants[variant.value]);
const countFormatted = computed(() => GlobalUtils.Currency.spaceInThousand(count.value));

const handleClick = () => {
  HeaderEvents.socialLinkClicked({ 'Link Place': EHeaderSocialLinkClickedPlaces.FOOTER, 'Social Type': variant.value });
};
</script>

<style scoped lang="scss" src="./SocialIcon.scss" />
