<template>
  <div class="payment-methods">
    <component
      :is="getComponentNameByMethod(method)"
      v-for="method in paymentMethods"
      :key="method"
      :class="getClassByMethod(method)"
    />
  </div>
</template>

<script setup lang="ts">
import type { TFooterPaymentMethods } from './PaymentMethods.types';

const {
  footer: { paymentMethods },
} = useProjectSettings();

const getComponentNameByMethod = (method: TFooterPaymentMethods) => {
  const capitalizedMethod = `${method[0].toUpperCase()}${method.slice(1)}`;
  return `LazySvgoFooter${capitalizedMethod}`;
};

const getClassByMethod = (method: TFooterPaymentMethods) => `payment-methods__${method}`;
</script>

<style scoped lang="scss" src="./PaymentMethods.scss"></style>
